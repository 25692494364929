function addFavorite(id, csrf_token) {
  $.ajax({
    url: '/favorites/' + id + '/',
    type: 'POST',
    data: {
      csrfmiddlewaretoken: csrf_token
    },
    success: function (data) {
      $(".favorite-icon[data-id=" + id +"]").removeClass('far').addClass('fas');
      if ($('.favorites-num').length) {
        var num_favorites = parseInt($('.favorites-num')[0].textContent) + 1;
        $('.favorites-num').html(num_favorites);
      } else {
        $('.nav-favorites').append('<div class="favorites-num">1</div>');
      }
    }
  })
}

function removeFavorite(id, csrf_token) {
  $.ajax({
    url: '/favorites/' + id + '/',
    type: 'DELETE',
    beforeSend: function (xhr) {
        xhr.setRequestHeader("X-CSRFToken", csrf_token);
    },
    success: function (data) {
      $(".favorite-icon[data-id=" + id +"]").removeClass('fas').addClass('far');
      var num_favorites = parseInt($('.favorites-num')[0].textContent);
      if (num_favorites == 1) {
        $('.favorites-num').remove();
      } else {
        num_favorites--;
        $('.favorites-num').html(num_favorites);
      }
    }
  })
}

$('#navbarTogglerMenu').on('hidden.bs.collapse', function () {
  $('.template-homepage .navbar').css({ backgroundColor: 'transparent' });
  $('.template-homepage #menu').addClass('navbar-dark');
  $('.template-homepage #menu').removeClass('navbar-light');
})

$('#navbarTogglerMenu').on('show.bs.collapse', function () {
  $('.template-homepage .navbar').css({ backgroundColor: 'white' });
  $('.template-homepage #menu').addClass('navbar-light');
  $('.template-homepage #menu').removeClass('navbar-dark');
})

$(document).ready(function () {
  //MENU
  var height;
  if ($('#gallery-header').length > 0) {
    height = $('#gallery-header').height();
  } else if ($('#generic-header').length > 0) {
    height = $('#generic-header').height();
  } else if ($('.village-section').length > 0 ) {
    height = $('#theme-filter').height();
  }

  window.addEventListener('scroll', function() {
    if ($(window).scrollTop() > height) {
      $('#menu').addClass('affix-menu');
      $('.triangle-logo').addClass('affix');
      $('.triangle-logo').addClass('fade-out');
      $('.navbar-brand').removeClass('custom-hide');
      $('.navbar-brand').addClass('d-block');
    } else {
      // Logic that breaks scroll navbar
      $('#menu').removeClass('affix-menu');
      $('.triangle-logo').removeClass('affix');
      $('.triangle-logo').removeClass('fade-out');
      $('.navbar-brand').addClass('custom-hide');
      $('.navbar-brand').removeClass('d-block');
    }

    footerOffset = $('#footer-links').offset().top;
    if ($(window).scrollTop() + $(window).height() > footerOffset) {
      $('.triangle-map').css({ position: 'absolute', top: footerOffset - $('.triangle-map').height(), bottom: 'auto' });
    } else {
      $('.triangle-map').css({ position: 'fixed', top: 'auto', bottom: 0 });
    }
  });
});

$("#scrollToTopBtn").click(function() {
  $([document.documentElement, document.body]).animate({
    scrollTop: ($("#menu").height())
  }, 1000);
});

$('.rich-text a').attr('target', '_blank');
